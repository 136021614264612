.mainbanner_sec {
  /* background-image: url("../../images/background_banner.jpg"); */
  /* background-size: cover; */
  margin-top: 40px;
  margin-bottom: 40px;
}

.shop_btn {
  height: 50px;
  width: 120px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  background: #1680f3;
}

.shop_btn:hover {
  background: #1676f3;
}

.banner_cont {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.banner_text {
  width: 30%;
}

.banner_box {
  position: relative;
  min-height: 400px;
  width: 70%;
  /* background-color: rgba(0, 0, 0, 0.5); */
  /* background-image: url("../../images/background_banner.jpg"); */
}

.banner_img {
  border-radius: 35px;
  position: sticky;
  z-index: 2;
}

.banner_back {
  background: linear-gradient(
    90deg,
    rgb(255, 255, 255) 0%,
    rgba(255, 255, 255, 0.106) 42%,
    rgba(255, 255, 255, 0) 100%
  );
  position: absolute;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  z-index: 3;
}

.banner_content {
  padding-top: 170px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.main_title {
  font-family: "Raleway";
  font-size: 60px;
  color: #fff;
  text-align: center;
}

.main_desc {
  margin-top: 50px;
  font-family: "Raleway";
  font-size: 30px;
  color: #fff;
  text-align: center;
}

.main_button {
  margin-top: 50px;
  font-family: "Raleway";
  font-size: 17px;
  color: #fff;
  padding: 20px 30px;
  border-radius: 35px;
  background: linear-gradient(135deg, #ff8800 0%, #cf3218 100%);
  /* background-color: #236ad6; */
  text-transform: uppercase;
}

.main_button:hover {
  background: linear-gradient(135deg, #cb540a 0%, #cf3218 100%);
  /* background-color: #3b8ac3; */
  color: #eedbcb;
}

@media screen and (max-width: 767.5px) {
  .banner_content {
    padding-top: 50px;
  }
  .main_title {
    font-size: 40px;
  }
  .main_desc {
    font-size: 20px;
  }
  .main_button {
    margin-top: 30px;

    font-size: 14px;

    padding: 15px 25px;
    border-radius: 35px;
  }
  .banner_cont {
    /* position: relative;
    z-index: 1; */
    flex-direction: column-reverse;
  }
  .banner_text {
    /* position: absolute; */
    /* text-align: center; */
    width: 100%;
  }
  .banner_box {
    width: 100%;
    min-height: auto;
    margin-bottom: 20px;
  }
}
