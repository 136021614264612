.form {
  display: flex;
  flex-direction: column;
  width: 400px;
}

.form2 {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.form_input {
  font-family: "Raleway";
  font-weight: 400;
  color: #444;
  font-size: 14px;
  /* border: 1px solid black; */
  height: 50px;
  border-radius: 5px;
  background-color: #f5f5f5;
  padding: 15px;
  margin-top: 20px;
}
.contact_form {
  font-family: "Raleway";
  font-weight: 500;
  font-size: 22px;
  text-align: center;
}

.form_mass {
  height: 100px;
}

.form_label {
  margin-top: 10px;
}

.form_btn {
  font-family: "Raleway";
  font-weight: 500;
  font-size: 15px;
  color: #fff;
  margin-top: 25px;
  height: 50px;
  background: linear-gradient(
    90deg,
    rgba(0, 186, 255, 1) 0%,
    rgba(22, 128, 243, 1) 57%,
    rgba(0, 212, 255, 1) 100%
  );
  border-radius: 25px;
}

.form_btn:hover {
  background: linear-gradient(135deg, #1676f3 0%, #1676f3 100%);
  color: #eedbcb;
}
.service_box {
  display: flex;
  justify-content: space-between;
}

.phone_footer {
  font-family: "Raleway";
  font-weight: 500;
  font-size: 18px;
  color: #444;
}

.cont_ph_box {
  margin-bottom: 20px;
}

.keys_img {
  width: 400px;
  height: 280px;
  margin-top: 20px;
  /* transform: rotate(45deg); */
  /* margin-left: 100px; */
}

.contact_socials {
  display: flex;
  margin-left: 20px;
  margin-top: 30px;
}

.social_item {
  width: 60px;
  height: 60px;
  margin-right: 20px;
}
.social_item:hover {
  cursor: pointer;
  transform: scale(1.25);
  transition: all 0.3s ease-in-out;
  /* box-shadow: 2px 4px 25px -13px rgba(255, 136, 0); */
}

@media screen and (min-width: 768px) and (max-width: 1199.5px) {
  .phone_footer {
    font-size: 12px;
    margin-left: 0;
  }
  .keys_img {
    width: 300px;
    height: 200px;
    margin-top: 20px;
  }
  .form {
    width: 300px;
  }
}

@media screen and (max-width: 767.5px) {
  .service_box {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .form {
    width: 300px;
  }
  .keys_img {
    width: 300px;
    height: 200px;
    margin-top: 20px;
  }
}
