.benefits_item {
  padding: 30px 20px;
  height: 280px;
  width: 320px;
  box-shadow: 2px 4px 25px -13px rgba(0, 0, 0, 0.75);
  border-radius: 10px;
  text-align: center;
  background-image: url("../../images/img_b1.jpg");
  background-size: cover;
}

.benefits_item2 {
  background-image: url("../../images/img_b2.jpg");
}

.benefits_item3 {
  background-image: url("../../images/img_b5.jpg");
}

.benefits_item:hover {
  /* background-color: #f5f5f5; */
  /* border-bottom: 3px solid #236ad6; */
  cursor: pointer;
  background-color: #ff8800;
  background-image: linear-gradient(
    135deg,
    rgba(0, 186, 255, 1) 0%,
    rgba(22, 128, 243, 1) 100%
  );
}

.benefits_text {
  font-family: "Raleway";
  font-weight: 500;
  color: #fff;
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 0;
}

.benefits_title {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 10px;
}

.benefits_desc {
  font-family: "Raleway";
  font-weight: 400;
  color: #444;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 0;
  text-align: center;
}

.benefits_icon {
  fill: rgba(22, 128, 243, 1);
  width: 50px;
  height: 50px;
  margin: 0 auto;
}

.benefits_item:hover .benefits_text {
  color: #fff;
}

.benefits_item:hover .benefits_desc {
  color: #fff;
}

.benefits_item:hover .benefits_icon {
  fill: #fff;
}
@media screen and (min-width: 768px) and (max-width: 1199.5px) {
  .benefits_item {
    margin: 10px;
  }
  .benefits_icon {
    width: 35px;
    height: 35px;
  }

  .benefits_text {
    font-size: 15px;
    margin-top: 5px;
  }

  .benefits_desc {
    font-size: 12px;
    margin-top: 5px;
  }
}

@media screen and (max-width: 767.5px) {
  .benefits_item {
    margin: 10px;
  }
}
