.menu_mob {
  display: block;
}

.item_mob {
  margin-top: 40px;
}

.burger {
  background-color: transparent;
}
.menu_item2 {
  font-family: "Raleway";
  font-weight: 700;
  transition: 0.3s ease;
  font-size: 20px;
  /* padding:  20px 0; */
  text-decoration: none;

  background-size: 200% 100%;
  background-position: -100%;
  display: inline-block;
  padding: 5px 0;
  margin-right: 25px;
  position: relative;
  text-transform: uppercase;
  color: black;
}

@media screen and (min-width: 767.5px) {
  .mob_box {
    display: none;
  }
}
