.btnUp {
  position: fixed;
  bottom: 10%;
  right: 10%;
  width: 45px;
  height: 45px;
  background-color: rgba(38, 38, 40, 0.4);
  border: none;
}
.btnUp:hover {
  background-color: rgba(22, 128, 243, 1);
}
.up {
  width: 25px;
  height: 25px;
  fill: white;
}
