.container2 {
  margin-right: auto;
  margin-left: auto;
  padding-right: 20px;
  padding-left: 20px;

  min-width: 280px;
  max-width: 480px;
}

@media screen and (min-width: 768px) {
  .container2 {
    max-width: 700px;
  }
}

@media screen and (min-width: 1200px) {
  .container2 {
    max-width: 1080px;
  }
}
