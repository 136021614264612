.accordion-item {
  border: none;
  background-color: #f5f5f5;
}

.accordion-body {
  height: 180px;
}

.accordion-button {
  background-color: #f5f5f5;
  margin: 0 auto;
}

.accordion-button::after {
  margin: 0 auto;
}

.accordion-button:not(.collapsed) {
  color: transparent;
  background-color: transparent;
}

.accordion-button:focus {
  border-color: transparent;
  box-shadow: none;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
}

.header_box_mob {
  background-color: #f5f5f5;
  height: 50px;
}

.header_wrap_mob {
  height: 100%;
}

.header_contacts_mob {
  height: 100%;
}

.header_social_mob {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-top: 50px;
}

.header_face_mob {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-left: 1px solid #e2e6e7;
  border-right: 1px solid #e2e6e7;
  padding: 0 10px;
}

@media screen and (min-width: 768px) {
  .header_mob {
    display: none;
  }
}
