.serv_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.service_item {
  padding: 30px;
  height: 220px;
  width: 220px;
  box-shadow: 2px 4px 25px -13px rgba(0, 0, 0, 0.75);
  border-radius: 10px;
  text-align: center;
}

.service_item:hover {
  background-color: #f5f5f5;
  border-bottom: 3px solid #236ad6;
  cursor: pointer;
}

.service_item_active {
  padding: 30px;
  text-align: center;
  height: 250px;
  width: 220px;
  box-shadow: 2px 4px 25px -13px rgba(0, 0, 0, 0.75);
  border-radius: 10px;
  background-color: #f5f5f5;
  border-bottom: 3px solid #236ad6;
}

.services_sec {
  padding: 60px 0;
}

.service_content {
  margin-top: 40px;
}

.service_title {
  font-family: "Raleway";
  text-align: center;
  font-size: 30px;
  font-weight: 500;
  margin: 0;
  text-transform: uppercase;
}

.line {
  height: 3px;
  width: 60px;
  background-color: rgba(22, 128, 243, 1);
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 60px;
}

.line2 {
  height: 1px;
  width: 60px;
  background-color: #236ad6;
  margin: 0 auto;
  margin-top: 15px;
  margin-bottom: 15px;
}

.service_icon {
  fill: #236ad6;
  width: 50px;
  height: 50px;
  margin: 0 auto;
}

.service_text {
  font-family: "Raleway";
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  /* margin-top: 22px; */
}

@media screen and (min-width: 768px) and (max-width: 1199.5px) {
  .service_item {
    padding: 20px;
    height: 180px;
    width: 155px;
  }

  .service_item_active {
    padding: 20px;
    height: 190px;
    width: 155px;
  }
  .service_text {
    font-size: 14px;
  }
}

@media screen and (max-width: 429px) {
  .serv_box {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
  .service_item {
    padding: 15px;
    height: 150px;
    width: 120px;
    max-height: 150px;
    max-width: 160px;
    margin: 5px;
  }

  .service_item_active {
    padding: 15px;
    height: 150px;
    width: 120px;
    max-height: 150px;
    max-width: 160px;
    margin: 5px;
  }

  .service_text {
    font-size: 12px;
    padding-top: 10px;
  }
  .line2 {
    height: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

@media screen and (min-width: 429.5px) and (max-width: 767.5px) {
  .serv_box {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .service_item {
    padding: 15px;
    height: 150px;
    width: 150px;

    margin: 20px;
  }

  .service_item_active {
    padding: 15px;
    height: 150px;
    width: 150px;

    margin: 20px;
  }

  .service_text {
    font-size: 12px;
    padding-top: 10px;
  }

  .line2 {
    height: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
}
