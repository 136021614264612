.footer_box {
  display: flex;
  justify-content: space-between;
}
.footer_title {
  font-family: "Raleway";
  font-weight: 500;
  font-size: 15px;
}
.footer_text {
  font-family: "Raleway";
  font-weight: 400;
  font-size: 14px;
  margin: 0;
}

.footer_item {
  width: 33%;
  margin-right: 50px;
}
.footer_item:last-child {
  width: 25%;
  margin-right: 0;
}

.footer_line {
  height: 2px;
  width: 40px;
  background-color: #ff8800;

  margin-top: 10px;
  margin-bottom: 20px;
}

.footer_li::before {
  content: url(../../images/check.svg);
  margin-right: 15px;
}

.footer_li {
  display: flex;
  align-items: center;
}

.footer_social {
  display: flex;
  align-items: center;
}

.footer_link {
  position: relative;
  display: block;
  width: 40px;
  height: 40px;
  margin-right: 5px;
  background-color: #ff8800;
}

.footer_link:hover {
  background-color: white;
  border: 1px solid #ff8800;
}

.footer_link:hover .footer_icon {
  fill: #ff8800;
}

.footer_icon {
  fill: white;
  width: 25px;
  height: 25px;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
}
.footer_ul {
  padding: 0;
}

@media screen and (max-width: 767.5px) {
  .footer_box {
    display: block;
  }
  .footer_item {
    width: 100%;
    margin-top: 40px;
  }
  .footer_item:first-child {
    margin-top: 0;
  }
  .footer_item:last-child {
    width: 100%;
  }
}

.service_sec {
  background-color: #f5f5f5;
  padding: 60px 0;
}

.footer_mes {
  display: flex;
}
